<template>
  <div class="service-content">
    <div class="w content ty300" ref="transformRef1">
      <div class="title-img">
        <img src="@/assets/images/smart-maintenance-image/page01.png" alt="">
      </div>

      <div class="item-box">
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="left">
            <img class="img1" :src="item.img" alt="">
            <img class="img2" :src="item.hoverImg" alt="">
          </div>
          <div class="right">
            <div class="title-box">
              <div class="title">{{ item.title }}</div>
            </div>
            <div class="text">{{ item.text }}</div>
          </div>
          <div class="posi-img">
            <img class="img1" src="@/assets/images/smart-maintenance-image/page35.png" alt="">
            <img class="img2" src="@/assets/images/smart-maintenance-image/page36.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from '@/assets/images/smart-maintenance-image/page06.png';
import img2 from '@/assets/images/smart-maintenance-image/page07.png';
import img3 from '@/assets/images/smart-maintenance-image/page08.png';
import img5 from '@/assets/images/smart-maintenance-image/page28.png';
import img6 from '@/assets/images/smart-maintenance-image/page29.png';
import img7 from '@/assets/images/smart-maintenance-image/page30.png';
import img8 from '@/assets/images/smart-maintenance-image/page37.png';
import img9 from '@/assets/images/smart-maintenance-image/page38.png';
import { reactive, toRefs, watchEffect } from 'vue';
import { itemGsap } from '@/views/index/js/usePage';

export default {
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const list = [
      {
        title: '冷链维保托管',
        text: '根据制冷系统制定维保检测机制，从不同维度，全方位监测评估冷库的各项指标。提供定制化的维保方案，并给予专业、细致的维保服务，保障冷库设备的最佳运行状态。',
        img: img3,
        hoverImg: img6,
      },
      {
        title: '远程技术支持',
        text: '提供24小时远程技术支持；通过物联网技术将维保项目机组设备接入BF-ROM线上平台，可以实现冷链设备运行工况实时监测，同时具备故障预警+报修联动等功能。',
        img: img5,
        hoverImg: img1,
      },
      {
        title: '维保配件集采',
        text: '基于严格的供应商遴选机制，结合多年的行业采购经验，提供性价比最优的解决方案。我们能提供设备配件选型、采购、安装、更换全流程的“管家式”服务。我专业维保、您安心运营！',
        img: img8,
        hoverImg: img9,
      },
      {
        title: '冰峰冷库管家',
        text: '全数字化的智慧维保方案：提供物联网设备接入的线下实施、线上平台监测、智慧运维一站式服务，帮助合作伙伴实现冷链智慧管理。',
        img: img2,
        hoverImg: img7,
      },
    ];
    const data = reactive({
      transformRef1: null,
      count: 0,
    });

    watchEffect(() => {
      if (props.isView) {
        data.count++;
      }
      if (props.isView && data.count === 1) {
        itemGsap(data.transformRef1, {
          y: 0,
          opacity: 1,
          duration: 1.5,
        });
      }
    });

    return {
      list,
      ...toRefs(data),
    };
  },
};
</script>

<style scoped lang="scss">
.service-content {
  width: 100vw;
  height: 60.313vw;
  background-image: url('~@/assets/images/smart-maintenance-image/page14.png');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% 100%;
  overflow: hidden;
  .title-img {
    width: 31.51vw;
    height: 5.625vw;
    margin-top: 4.635vw;
    margin-bottom: 5.729vw;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .item-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      width: 36.979vw;
      height: 15.625vw;
      margin-bottom: 3.646vw;
      display: flex;
      padding: 3.333vw 5.833vw 0 1.979vw;
      background-color: #FFFFFF;
      position: relative;

      .left {
        width: 3.802vw;
        height: 3.542vw;
        margin-right: 1.979vw;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
        .img1 {
          display: block;
        }
        .img2 {
          display: none;
        }
      }
      .right {
        flex: 1;
        .title-box {
          display: flex;
          .title {
            height: 1.25vw;
            font-size: 1.146vw;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: var(--baseColor1);
            line-height: 1.354vw;
            position: relative;
          }
        }
        .text {
          margin-top: 1.042vw;
          height: 5.677vw;
          font-size: 0.833vw;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #000;
          line-height: 1.563vw;
        }
      }
      &:hover {
        background-color: var(--baseColor1);
        .title-box {
          .title {
            color: #fff;
          }
        }
        .right {
          .text {
            color: #fff;
          }
        }
        .left {
          .img1 {
            display: none;
          }
          .img2 {
            display: block;
          }
        }
      }
      .posi-img {
        position: absolute;
        right: 1.198vw;
        bottom: 0.729vw;
        width: 4.74vw;
        height: 5.104vw;
        z-index: 10;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
        .img2 {
          display: none;
        }
      }
      &:hover {
        .posi-img {
          .img1 {
            display: none;
          }
          .img2 {
            display: block;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .service-content {
    width: 100vw;
    height: auto;
    background-image: url('~@/assets/images/smart-maintenance-image/page14.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: auto 100%;
    overflow: hidden;
    .title-img {
      width: 50vw;
      height: auto;
      margin-top: 4.635vw;
      margin-left: 4vw;
      margin-bottom: 1.729vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .item-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 1.333vw;
      .item {
        width: 100%;
        height: 25vw;
        margin-bottom: 3.646vw;
        display: flex;
        padding: 3.333vw 5.833vw 0 1.979vw;
        background-color: #FFFFFF;
        position: relative;

        .left {
          width: 8vw;
          height: 100%;
          margin-right: 1.979vw;
          img {
            display: block;
            width: 8vw;
            height: 8vw;
          }
          .img1 {
            display: block;
          }
          .img2 {
            display: none;
          }
        }
        .right {
          flex: 1;
          .title-box {
            display: flex;
            .title {
              height: 2.667vw;
              font-size: 2.667vw;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: var(--baseColor1);
              line-height: 2.667vw;
              position: relative;
            }
          }
          .text {
            margin-top: 1.042vw;
            height: 5.677vw;
            font-size: 1.867vw;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #000;
            line-height: 3.733vw;
          }
        }
        &:hover {
          background-color: var(--baseColor1);
          .title-box {
            .title {
              color: #fff;
            }
          }
          .right {
            .text {
              color: #fff;
            }
          }
          .left {
            .img1 {
              display: none;
            }
            .img2 {
              display: block;
            }
          }
        }
        .posi-img {
          position: absolute;
          right: 1.198vw;
          bottom: 0.729vw;
          width: 9.74vw;
          height: 10.104vw;
          z-index: 10;
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
          .img2 {
            display: none;
          }
        }
        &:hover {
          .posi-img {
            .img1 {
              display: none;
            }
            .img2 {
              display: block;
            }
          }
        }
      }
    }
  }
}
</style>
